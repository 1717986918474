import { navigate } from 'gatsby'
import React, { ReactElement, useCallback } from 'react'

export default function Forms(): ReactElement {
  const handleClick = useCallback(() => {
    navigate('/')
  }, [])

  return <>Oops. Page Not Found</>
}
